import { useScrollToTopOnMount } from "hooks"
import type { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { usePageTitle } from "../../tools/usePageTitle"
import { UserProfileCardConnected } from "./UserProfileCard"
import { UserProfileCertificatesConnected } from "./UserProfileCertificates"
import { UserProfileSettingsConnected } from "./UserProfileSettings"

import s from "./styles.module.scss"

export const UserProfileView = () => {
  const { t } = useTranslation()
  useScrollToTopOnMount()
  usePageTitle(t("pageTitle.YOUR_PROFILE"))

  return (
    <UserProfileView.Wrapper>
      <UserProfileCardConnected />
      <UserProfileCertificatesConnected />
      <UserProfileSettingsConnected />
    </UserProfileView.Wrapper>
  )
}

UserProfileView.Wrapper = ({ children }: { children: ReactNode }) => (
  <div className={s.userProfileView}>{children}</div>
)

UserProfileView.Section = ({
  title,
  children,
}: {
  title?: string
  children: React.ReactNode
}) => (
  <div className={s.section}>
    {title && <h2 className={s.section__title}>{title}</h2>}
    {children}
  </div>
)
