import { ErrorFallback } from "materia"
import { useTranslation } from "react-i18next"
import { sanitizeHtml } from "ui-tools"
import { FiestaImage } from "utility-components"
import s from "./styles.module.scss"

type Props = {
  body: string | null
  headline: string | null
  image: string | null
}
export const NewsPostViewContent = ({ body, headline, image }: Props) => {
  return (
    <div className={s.content}>
      <FiestaImage
        className={s.content__image}
        src={image ?? ""}
        alt="News post"
        sizes="(width <= small) 100vw, 50vw"
      />
      <h1 className={s.content__title}>{headline}</h1>
      <div
        className={s.content__description}
        dangerouslySetInnerHTML={sanitizeHtml(body ?? "", "allow-links")}
      />
    </div>
  )
}

const Skeleton = () => (
  <div className={s.skeleton__content}>
    <div className={s.skeleton__image} />
    <div className={s.skeleton__title} />
    <ul className={s.skeleton__description}>
      <li className={s.skeleton__descriptionItem} />
      <li className={s.skeleton__descriptionItem} />
      <li className={s.skeleton__descriptionItem} />
      <li className={s.skeleton__descriptionItem} />
      <li className={s.skeleton__descriptionItem} />
      <li className={s.skeleton__descriptionItem} />
      <li className={s.skeleton__descriptionItem} />
    </ul>
  </div>
)

const Error = ({ error }: { error: unknown }) => {
  const { t } = useTranslation()

  return (
    <div className={s.content}>
      <div className={s.content__image}>
        <ErrorFallback error={error} title="" />
      </div>
      <h1 className={s.content__title}>{t("error.GENERIC")}</h1>
      <div className={s.content__description}>{t("error.NEWS_POST_ERROR")}</div>
    </div>
  )
}

NewsPostViewContent.Skeleton = Skeleton
NewsPostViewContent.Error = Error
