import { v3 } from "backoffice-api"
import { leaderboard } from "leaderboard-api"
import { useTranslation } from "react-i18next"
import { QueryBoundary } from "utility-components"
import { custom } from "../../../bonzai/bonzai"
import { useFormatUser } from "../../../bonzai/useFormatUser"
import { getMaxScore } from "../../../dataUtilities/getMaxScore"
import { getProductLink } from "../../../dataUtilities/productDataUtilities"
import { usePickText } from "../../../i18n/usePickText"
import { LeaderboardEntry } from "../../LeaderboardEntry"
import { LeaderboardWidget } from "./LeaderboardWidget"

export const LeaderboardWidgetLoader = () => (
  <QueryBoundary fallback={<LeaderboardWidget.Skeleton />}>
    <Load />
  </QueryBoundary>
)

const Load = () => {
  const { t } = useTranslation()

  const { product, highScores, isError } = useData()

  const formatUser = useFormatUser()
  const pickText = usePickText()

  // TODO [error-handling-effort] https://jira.attensi.com/browse/WEB-18497
  // ATM we don't know if this widget is loading, so it's hidden
  // during loading (since data is not present yet). I decided to keep
  // it hidden when an error throws so we don't have an error appearing
  // out of the blue. Add a proper loading state and a proper error state
  // in the future
  if (isError || !product || !highScores) return null

  const maxScore = getMaxScore(highScores.entries)
  const productType = product.product_type.identifier
  const showMyEntry = highScores.my_entry.value > 0

  const items = highScores.entries.map((entry, index) => (
    <LeaderboardEntry
      key={index}
      image={entry.user.image}
      imageDescription={entry.user.image_title["en-US"]}
      isFirst={index === 0}
      maxScore={maxScore}
      name={formatUser(entry.user)}
      rank={entry.rank}
      score={entry.value}
    />
  ))

  return (
    <LeaderboardWidget
      link={{ to: getProductLink(product.id, productType) }}
      ariaLabel={pickText(product.title)}
    >
      <LeaderboardWidget.Header
        title={pickText(product.title)}
        caption={t("leaderboard.PEOPLE_ARE_PLAYING")}
      />
      <LeaderboardWidget.Entries>
        {items}
        {showMyEntry && (
          <LeaderboardEntry
            image={highScores.my_entry.user.image}
            imageDescription={highScores.my_entry.user.image_title["en-US"]}
            isFirst={false}
            maxScore={maxScore}
            name={formatUser(highScores.my_entry.user)}
            rank={highScores.my_entry.rank}
            score={highScores.my_entry.value}
          />
        )}
      </LeaderboardWidget.Entries>
    </LeaderboardWidget>
  )
}

const useData = () => {
  const product_id = leaderboard.getMostPlayedProducts.useSuspenseQuery(
    [{ length: 1 }],
    { select: (res) => res.products[0]?.product_id }
  )

  const { data: product, isError: isProductError } = v3.getProduct.useQuery(
    [product_id!],
    {
      // TODO [error-handling-effort] https://jira.attensi.com/browse/WEB-18497
      // When the task above is done, we'll remove throwOnError
      throwOnError: false,
      enabled: product_id !== undefined,
      select: (res) => res.data,
    }
  )

  const { data: scope, isError: isLeaderboardScopesError } =
    custom.getLeaderboardScopes.useQuery([], {
      // TODO [error-handling-effort] https://jira.attensi.com/browse/WEB-18497
      // When the task above is done, we'll remove throwOnError
      throwOnError: false,
      enabled: product_id !== undefined,
      select: (res) => res[0]?.value,
    })

  const { data: highScores, isError: isHighScoresError } =
    leaderboard.getHighScores.useQuery(
      [{ product_id: product_id!, scope: scope!, length: 4 }],
      {
        // TODO [error-handling-effort] https://jira.attensi.com/browse/WEB-18497
        // When the task above is done, we'll remove throwOnError
        throwOnError: false,
        enabled: product_id !== undefined && scope !== undefined,
      }
    )

  const isError =
    isProductError || isLeaderboardScopesError || isHighScoresError

  return { product, highScores, isError }
}
