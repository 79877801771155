import { formatDate } from "format"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { FiestaLink } from "utility-components"
import { getProductLink } from "../../../dataUtilities/productDataUtilities"
import s from "./styles.module.scss"

type EventProps = {
  eventId: string
  title: string | null
  startDatetime: string
  isAttending: boolean
}
export const Event = ({
  eventId,
  startDatetime,
  title,
  isAttending,
}: EventProps) => {
  const { t, i18n } = useTranslation()

  return (
    <FiestaLink
      className={modifiers(s, "event", { isAttending })}
      to={getProductLink(eventId, "meet")}
      replace={true}
    >
      <div className={s.event__details}>
        <div className={s.event__date}>
          <div className={s.event__month}>
            {formatCourseMonth(startDatetime, i18n.language)}
          </div>
          <div className={s.event__day}>{formatCourseDay(startDatetime)}</div>
        </div>
        <div className={modifiers(s, "event__title", { isAttending })}>
          {title}
        </div>
      </div>
      <div className={modifiers(s, "event__status", { isAttending })}>
        {isAttending ? t("event.ATTENDING") : t("event.ATTEND")}
      </div>
    </FiestaLink>
  )
}

Event.Skeleton = () => {
  return (
    <div className={s.event}>
      <div className={s.event__details}>
        <div className={s.skeleton__date} />
        <div className={s.skeleton__title} />
      </div>
      <div className={s.skeleton__status} />
    </div>
  )
}

const formatCourseDay = (startDatetime: string) => {
  const date = new Date(startDatetime)

  return date.getDate().toString()
}

const formatCourseMonth = (startDatetime: string, locale: string) => {
  return formatDate(startDatetime, locale, {
    month: "short",
  })
}
