import { NewsPostViewBottomBar } from "./components/NewsPostViewBottomBar"
import { NewsPostViewContent } from "./components/NewsPostViewContent"
import { NewsPostViewWrapper } from "./components/NewsPostViewWrapper"

export const NewsPostViewSkeleton = () => {
  return (
    <NewsPostViewWrapper>
      <NewsPostViewContent.Skeleton />
      <NewsPostViewBottomBar.Skeleton />
    </NewsPostViewWrapper>
  )
}
