import { Card, ErrorFallback } from "materia"
import { useTranslation } from "react-i18next"
import s from "../styles.module.scss"

export const BundleProductsError = ({ error }: { error: unknown }) => {
  const { t } = useTranslation()
  return (
    <Card borderRadius="24">
      <div className={s.error}>
        <ErrorFallback
          error={error}
          title={t("error.GENERIC")}
          message={t("error.BUNDLE_PRODUCTS_FETCH_ERROR")}
        />
      </div>
    </Card>
  )
}
