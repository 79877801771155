import sharedDeviceIcon from "assets/icons/sharedDeviceIcon.svg"
import { useTranslation } from "react-i18next"
import { sendVuplexMessage } from "../../../../vuplex/sendVuplexMessage"
import { useSharedDeviceStore } from "../../../SharedDeviceInactivityTrackerModal/store"
import { ToggleItem } from "../components"

export const ToggleSharedDevice = () => {
  const { t } = useTranslation()
  // isSharedDevice is the current device state, describing if the device is shared or not.
  const { isSharedDevice, setIsSharedDevice, mustUseSharedDevice } =
    useSharedDeviceStore()

  const toggleSharedDevice = (newValue: boolean) => {
    setIsSharedDevice(newValue)
    sendVuplexMessage({
      type: "SET_IS_SHARED_DEVICE",
      payload: { isSharedDevice: newValue },
    })
  }

  return (
    <ToggleItem
      icon={sharedDeviceIcon}
      title={t("settings.SHARED_DEVICE")}
      checked={isSharedDevice}
      onChange={toggleSharedDevice}
      disabled={mustUseSharedDevice}
    />
  )
}
