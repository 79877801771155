import { type ResultOf } from "graphql-mock"
import { graphql } from "../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query NewsView {
    newsPosts {
      nodes {
        body
        headline
        id
        image
        isLiked
        likeCount
        updatedAt
      }
    }
  }
`)

export const initialData = {
  newsPosts: { nodes: [] },
}
