import productImage from "assets/demo/productImage.svg"
import { mockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../graphql"

export type Data = ResultOf<typeof productCardFragment>
export type Course = NonNullable<Data["course"]>
export type Progress = Data["progress"]
export type DeadlineOccurrence = NonNullable<Data["deadlineOccurrence"]>

export const productCardFragment = graphql(`
  fragment ProductCard on Product {
    id
    image
    title
    productType
    isLocked
    hasPlayedXapi
    progress {
      mandatory
      certified
      starsCount
      starsMax
      highScore
    }
    activityProgress {
      complete
      progressCount
      progressOf
    }
    deadlineOccurrence {
      status
    }
    courses(limit: 10, page: 1) {
      nodes {
        isFull
      }
    }
    course {
      isAttending
      isOnWaitingList
      isFull
      isCancelled
      startDatetime
    }
  }
`)

export const mockProductCardData = mockData<Data>({
  id: "1",
  image: productImage,
  productType: "pdf",
  title: "Product Title",
  isLocked: false,
  hasPlayedXapi: false,
  courses: {
    nodes: [{ isFull: true }, { isFull: false }],
  },
  progress: {
    mandatory: true,
    certified: true,
    highScore: 1334,
    starsCount: 3,
    starsMax: 1,
  },
  activityProgress: {
    complete: true,
    progressCount: 5,
    progressOf: 10,
  },
  deadlineOccurrence: {
    status: "certified",
  },
  course: {
    isAttending: false,
    isCancelled: false,
    isFull: false,
    isOnWaitingList: false,
    startDatetime: "2053-11-07T14:15:34.989Z",
  },
})
