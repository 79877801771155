import languageIcon from "assets/icons/languageIcon.svg"
import { Dropdown } from "materia"
import { useTranslation } from "react-i18next"
import { getLocaleDisplayName } from "../../../../dataUtilities/getLocaleDisplayName"
import { changeLocale } from "../../../../i18n/changeLocale"
import { sendVuplexMessage } from "../../../../vuplex/sendVuplexMessage"
import { BigItem } from "../components"

type Props = { portalSupportedLocales: string[] }
export const DropdownLanguage = ({ portalSupportedLocales }: Props) => {
  const { t, i18n } = useTranslation()

  const onLanguageChange = (localeCode: string) => {
    changeLocale(localeCode)
    sendVuplexMessage({ type: "SET_LANGUAGE", payload: { localeCode } })
  }

  // TODO [WEB-19290] - refactor this so it uses Chevron
  return (
    <Dropdown value={i18n.language} onChange={onLanguageChange}>
      <Dropdown.CustomButton>
        <BigItem
          title={t("settings.LANGUAGE")}
          icon={languageIcon}
          description={getLocaleDisplayName(i18n.language)}
          isInteractive={false}
        />
      </Dropdown.CustomButton>
      <Dropdown.Options>
        {portalSupportedLocales.map((locale) => (
          <Dropdown.Option key={locale} value={locale}>
            {getLocaleDisplayName(locale)}
          </Dropdown.Option>
        ))}
      </Dropdown.Options>
    </Dropdown>
  )
}
