import s from "./styles.module.scss"

export const NavigationSkeleton = () => {
  return (
    <div className={s.navigationBar}>
      <nav className={s.navigation}>
        <ul className={s.skeleton}>
          <li className={s.skeleton__link} />
          <li className={s.skeleton__link} />
          <li className={s.skeleton__link} />
          <li className={s.skeleton__link} />
          <li className={s.skeleton__link} />
        </ul>
      </nav>
    </div>
  )
}
