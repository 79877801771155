import { type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query NewsWidget {
    newsPosts(page: 1, limit: 3) {
      nodes {
        id
        image
        headline
        body
      }
    }
  }
`)
