import chevronIcon from "assets/icons/chevronIcon.svg"
import { ProgressBar, useErrorSentryLog } from "materia"
import { useTranslation } from "react-i18next"
import { Stars } from "../../../components/Stars"
import { useNavigateBack } from "../../../tools/useNavigateBack"
import s from "./styles.module.scss"

export const BundleHeaderError = ({ error }: { error: unknown }) => {
  // TODO [sentry-logging] https://jira.attensi.com/browse/WEB-18745
  // Find a better place to send Sentry logs from
  useErrorSentryLog(error)
  const navigateBack = useNavigateBack()
  const { t } = useTranslation()

  return (
    <div className={s.error}>
      <div className={s.header__centered}>
        <div className={s.header__widgetLayoutSize}>
          <button className={s.header__back} onClick={() => navigateBack()}>
            <img className={s.header__backIcon} src={chevronIcon} alt="" />
          </button>
          <div className={s.header__title}>
            {t("error.BUNDLE_HEADER_FETCH_ERROR")}
          </div>
          <div className={s.header__progress}>
            <ProgressBar value={0} maxValue={1} />
          </div>
          <div className={s.header__stars}>
            <Stars count={1} max={1} size="extraSmall" />
            ?/?
          </div>
        </div>
      </div>
    </div>
  )
}
