import { useWindowWidth } from "materia"
import type { ReactNode } from "react"
import { getChromaticProps } from "ui-tools"
import { DiplomaWidget } from "./DiplomaWidget"
import { EventWidget } from "./EventWidget"
import { LeaderboardWidget } from "./LeaderboardWidget/LeaderboardWidget"
import { NewsWidget } from "./NewsWidget"
import { RecentActivityWidget } from "./RecentActivityWidget"
import { UserWidgetLoading } from "./UserWidget/loading"
import s from "./WidgetLayout.module.scss"

type WidgetLayoutProps = {
  widgets: ReactNode
  children: ReactNode
  chromaticIgnore?: boolean
}
export const WidgetLayout = ({
  children,
  widgets,
  chromaticIgnore,
}: WidgetLayoutProps) => {
  const { isBigWindow } = useWindowWidth()

  return (
    <div className={s.widgetLayout}>
      <div className={s.widgetLayout__content}>{children}</div>
      {isBigWindow && (
        <aside
          className={s.widgetLayout__widgets}
          {...getChromaticProps(chromaticIgnore)}
        >
          {widgets}
        </aside>
      )}
    </div>
  )
}

const WidgetsSkeleton = () => (
  <>
    <UserWidgetLoading />
    <DiplomaWidget isLoading error={null} diplomas={[]} />
    <EventWidget isLoading error={null} events={[]} />
    <NewsWidget isLoading error={null} newsPosts={[]} />
    <LeaderboardWidget.Skeleton />
    <RecentActivityWidget isLoading error={null} activityEntries={[]} />
  </>
)

WidgetLayout.WidgetsSkeleton = WidgetsSkeleton
