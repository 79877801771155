import { type ResultOf } from "graphql-mock"
import { graphql } from "../../graphql"

export type Data = ResultOf<typeof query>
export type Product = Data["product"]

export const query = graphql(`
  query DiplomaUnlockModal($id: ID!) {
    product(id: $id) {
      progress {
        certified
      }
      diplomaConfiguration {
        title
        description
      }
    }
  }
`)
