import { modifiers } from "ui-tools"
import s from "./styles.module.scss"

type AvatarProps = {
  image: string
  className?: string
  alt: string
  size: "small" | "medium" | "large" | "extraLarge"
}

export const Avatar = ({
  image,
  className = "", // Should generally never be used. Especially to overwrite styles that the component tries to enforce - fx. size
  alt,
  size,
}: AvatarProps) => {
  return (
    <img
      className={`${modifiers(s, "avatar", size)} ${className}`}
      src={image}
      alt={alt}
    />
  )
}

Avatar.Skeleton = ({
  size,
  className,
}: Pick<AvatarProps, "size" | "className">) => {
  return (
    <div
      className={`${modifiers(s, "avatar", size, {
        isLoading: true,
      })} ${className}`}
    ></div>
  )
}
