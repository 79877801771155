import helpAttensiIcon from "assets/icons/helpAttensiIcon.svg"
import { useTranslation } from "react-i18next"
import { BigItem } from "../components"

type LinkHelpCenterProps = { onClick: () => void }
export const LinkHelpCenter = ({ onClick }: LinkHelpCenterProps) => {
  const { t } = useTranslation()

  return (
    <BigItem
      title={t("settings.HELP_ATTENSI")}
      icon={helpAttensiIcon}
      description={t("settings.HELP_ATTENSI_DESCRIPTION")}
      onClick={onClick}
      href="https://help.attensi.com/hc/en-us"
    />
  )
}
