import { type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query BundleHeader($id: ID!) {
    productBundle(id: $id) {
      id
      title
      progress {
        certifiedCount
        certifiedMax
        starsCount
        starsMax
      }
    }
  }
`)
