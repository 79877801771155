import avatarDefault from "assets/images/avatarDefault.svg"
import { useErrorSentryLog } from "materia"
import { useTranslation } from "react-i18next"
import { useCurrentCompanyContext } from "../../../components/AppShell/CurrentCompanyProvider"
import { useCurrentUserContext } from "../../../components/AppShell/CurrentUserProvider"
import { Avatar } from "../../../components/Avatar"
import s from "./styles.module.scss"

export const UserProfileCardConnected = () => {
  const {
    currentCompany: { backgroundUrl },
    currentCompanyError,
    isCurrentCompanyPending,
  } = useCurrentCompanyContext()
  const {
    currentUser: { avatar, avatarTitle, displayName, position },
    currentUserError,
    isCurrentUserPending,
  } = useCurrentUserContext()

  const isPending = isCurrentUserPending || isCurrentCompanyPending
  const errors = [currentUserError, currentCompanyError].filter(Boolean)

  return (
    <UserProfileCard
      isLoading={isPending}
      errors={errors}
      user={{ avatar, avatarTitle, backgroundUrl, displayName, position }}
    />
  )
}

type UserProfileCardProps = {
  isLoading: boolean
  errors: unknown[]
  user: {
    avatar: string
    avatarTitle: string
    backgroundUrl: string
    displayName: string | null
    position: string | null
  }
}
export const UserProfileCard = ({
  isLoading,
  errors,
  user: { avatar, avatarTitle, backgroundUrl, displayName, position },
}: UserProfileCardProps) => {
  if (isLoading) return <UserProfileCardSkeleton />
  if (errors.length > 0) return <UserProfileCardError errors={errors} />

  return (
    <div className={s.userCard}>
      {backgroundUrl && (
        <img src={backgroundUrl} className={s.userCard__cover} alt="" />
      )}
      <div className={s.userCard__info}>
        <div className={s.userCard__avatarBadge}>
          <Avatar image={avatar} alt={avatarTitle} size="extraLarge" />
        </div>
        <h1 className={s.userCard__name}>
          <div className={s.userCard__displayName}>{displayName}</div>
          <div className={s.userCard__department}>{position}</div>
        </h1>
      </div>
    </div>
  )
}

const UserProfileCardSkeleton = () => {
  return (
    <div className={s.userCard}>
      <div className={s.userCard__info}>
        <div className={s.userCard__avatarBadge}>
          <Avatar.Skeleton size="extraLarge" />
        </div>
        <h1 className={s.userCard__name}>
          <div className={s.skeleton__displayName} />
          <div className={s.skeleton__department} />
        </h1>
      </div>
    </div>
  )
}

const UserProfileCardError = ({ errors }: { errors: unknown[] }) => {
  const { t } = useTranslation()
  useErrorSentryLog(errors)

  return (
    <div className={s.userCard}>
      <div className={s.userCard__info}>
        <div className={s.userCard__avatarBadge}>
          <Avatar image={avatarDefault} size="extraLarge" alt="" />
        </div>
        <h1 className={s.userCard__name}>
          <div className={s.userCard__displayName}>
            {t("error.UNKNOWN_USER")}
          </div>
          <div className={s.userCard__department}>
            {t("error.UNKNOWN_POSITION")}
          </div>
        </h1>
      </div>
    </div>
  )
}
