import diplomaLockedIcon from "assets/icons/diplomaLocked.svg"
import diplomaUnlockedIcon from "assets/icons/diplomaUnlocked.svg"
import { useGraphqlQuery } from "graphql-mock"
import { times } from "lodash-es"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { Deadline } from "../../Deadline"
import { Widget } from "../../Widget"
import { query } from "./gql"

import s from "./styles.module.scss"

export const DiplomaWidgetConnected = () => {
  const { data, error, isPending } = useGraphqlQuery(query, {
    page: 1,
    limit: 3,
  })

  const diplomas = data?.products.nodes ?? []

  return (
    <DiplomaWidget isLoading={isPending} error={error} diplomas={diplomas} />
  )
}

type DiplomaWidgetProps = {
  isLoading: boolean
  error: unknown
  diplomas: {
    id: string
    progress: { certified: boolean }
    diplomaConfiguration: { title: string | null } | null
    deadlineOccurrence: { status: string } | null
  }[]
}
export const DiplomaWidget = ({
  diplomas,
  isLoading,
  error,
}: DiplomaWidgetProps) => {
  const { t } = useTranslation()

  const getContent = () => {
    if (isLoading)
      return (
        <>
          {times(3, (index) => (
            <div className={s.diploma} key={index}>
              <div className={s.diploma__info}>
                <div className={s.skeleton__icon} />
                <div className={s.skeleton__name} />
              </div>
              <div className={s.skeleton__deadline} />
            </div>
          ))}
        </>
      )

    if (error)
      return (
        <p className={s.widget__error}>{t("error.DIPLOMAS_FETCH_ERROR")}</p>
      )

    return (
      <>
        {diplomas.map((diploma) => (
          <Diploma
            key={diploma.id}
            title={diploma.diplomaConfiguration?.title}
            isUnlocked={diploma.progress.certified}
            deadlineStatus={diploma.deadlineOccurrence?.status}
          />
        ))}
      </>
    )
  }

  const isEmpty = !diplomas.length && !isLoading && !error
  // TODO Think if it should show "No certificates" or just not render altogether
  if (isEmpty) return null

  return (
    <Widget
      title={t("diploma.WIDGET_TITLE")}
      link={{ to: "/me", ariaLabel: "Open my profile" }}
      error={error}
    >
      <div className={s.diplomaList}>{getContent()}</div>
    </Widget>
  )
}

type DiplomaProps = {
  deadlineStatus?: string
  title?: string | null
  isUnlocked: Boolean
}
const Diploma = ({ deadlineStatus, title, isUnlocked }: DiplomaProps) => {
  const getDeadlineStatus = () => {
    switch (deadlineStatus) {
      case "due":
        return <Deadline variant="dueDate" size="small" />
      case "overdue":
        return <Deadline variant="overdue" size="small" />
    }
  }

  return (
    <div className={s.diploma}>
      <div className={s.diploma__info}>
        <div className={modifiers(s, "diploma__icon", { isUnlocked })}>
          {isUnlocked ? (
            <img src={diplomaUnlockedIcon} alt="Unlocked" />
          ) : (
            <img src={diplomaLockedIcon} alt="Locked" />
          )}
        </div>
        <div className={modifiers(s, "diploma__name", { isUnlocked })}>
          {title}
        </div>
      </div>
      <div className={s.diploma__deadline}>{getDeadlineStatus()}</div>
    </div>
  )
}
