import chevronIcon from "assets/icons/chevronIcon.svg"
import { useMenu } from "hooks"
import { modifiers } from "ui-tools"
import { MenuAnimation } from "../../../MenuAnimation"
import { CompanyPicker } from "../../CompanyPicker"
import { LinkOrButton } from "../components"

import s from "./styles.module.scss"

type DropdownCompanyProps = { name: string | null; logoUrl: string }
export const DropdownCompany = ({ name, logoUrl }: DropdownCompanyProps) => {
  const { isOpen, toggleMenu, containerRef } = useMenu()

  return (
    <div className={modifiers(s, "company", { isOpen })} ref={containerRef}>
      <LinkOrButton
        className={s.company__button}
        onClick={toggleMenu}
        aria-expanded={isOpen}
      >
        <div className={s.company__left}>
          <img className={s.company__icon} src={logoUrl} alt="" />
          <div className={s.company__name}>{name}</div>
        </div>
        <img className={s.company__chevron} src={chevronIcon} alt="" />
      </LinkOrButton>

      <MenuAnimation className={s.company__menu} isOpen={isOpen}>
        <CompanyPicker />
      </MenuAnimation>
    </div>
  )
}
