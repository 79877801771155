import chevronIcon from "assets/icons/chevronIcon.svg"
import { useGraphqlQuery } from "graphql-mock"
import { ProgressBar } from "materia"
import { Stars } from "../../../components/Stars"
import { useNavigateBack } from "../../../tools/useNavigateBack"
import { BundleHeaderError } from "./error"
import { query } from "./gql"
import { BundleHeaderSkeleton } from "./loading"

import s from "./styles.module.scss"

export const BundleHeaderConnected = ({ id }: { id: string }) => {
  const { data, error, isPending } = useGraphqlQuery(query, { id })

  if (isPending) return <BundleHeader.Skeleton />
  if (error) return <BundleHeader.Error error={error} />

  const { productBundle } = data
  const { progress, title } = productBundle
  const { certifiedCount, certifiedMax, starsCount, starsMax } = progress

  return (
    <BundleHeader
      title={title}
      certifiedCount={certifiedCount}
      certifiedMax={certifiedMax}
      starsCount={starsCount}
      starsMax={starsMax}
    />
  )
}

type BundleHeaderProps = {
  title: string | null
  certifiedCount: number
  certifiedMax: number
  starsCount: number
  starsMax: number
}
export const BundleHeader = ({
  title,
  certifiedCount,
  certifiedMax,
  starsCount,
  starsMax,
}: BundleHeaderProps) => {
  const navigateBack = useNavigateBack()
  const isBundleWithScore = starsMax > 0

  return (
    <div className={s.header}>
      <div className={s.header__centered}>
        <div className={s.header__widgetLayoutSize}>
          <button className={s.header__back} onClick={() => navigateBack()}>
            <img className={s.header__backIcon} src={chevronIcon} alt="" />
          </button>
          <div className={s.header__title}>{title}</div>
          {isBundleWithScore && (
            <>
              <div className={s.header__progress}>
                <ProgressBar
                  value={certifiedCount}
                  maxValue={certifiedMax}
                  color="purple-60"
                  backgroundColor="purple-10"
                />
              </div>
              <div className={s.header__stars}>
                <Stars count={1} max={1} size="extraSmall" />
                {starsCount}/{starsMax}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

BundleHeader.Skeleton = BundleHeaderSkeleton
BundleHeader.Error = BundleHeaderError
