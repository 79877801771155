import { ReactComponent as MagicStar } from "assets/icons/starMagic.svg"
import certificateBadgeUnlocked from "assets/illustrations/certificateBadgeUnlocked.svg"
import unlockedSunrise from "assets/illustrations/unlockedSunrise.svg"
import { useGraphqlQuery } from "graphql-mock"
import { useOnEscape } from "hooks"
import { useDisableDocumentScroll } from "hooks/src/useDisableDocumentScroll"
import { times } from "lodash-es"
import { Button, Modal } from "materia"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { backgroundImageStyle } from "ui-tools"
import { useConfettiBannerStore } from "../ConfettiBanner/store"
import { query } from "./gql"
import s from "./styles.module.scss"

export const DiplomaUnlockModalConnected = () => {
  const { isBlocked, progressChange } = useConfettiBannerStore()
  const [isOpen, setIsOpen] = useState(true)

  const { data, error, isPending } = useGraphqlQuery(
    query,
    { id: progressChange?.productId! },
    { enabled: progressChange !== undefined }
  )

  const onClose = () => setIsOpen(false)

  const diplomaConfiguration = data?.product.diplomaConfiguration
  const isCertified = data?.product.progress.certified

  const isDiplomaFreshlyUnlocked = Boolean(
    progressChange && isCertified && !isPending && !error
  )
  const shouldShowModal =
    diplomaConfiguration && isDiplomaFreshlyUnlocked && isOpen && !isBlocked

  if (!shouldShowModal) return null
  return (
    <DiplomaUnlockModal
      title={diplomaConfiguration.title}
      description={diplomaConfiguration.description}
      onClose={onClose}
    />
  )
}

type DiplomaUnlockModalProps = {
  title: string | null
  description: string | null
  onClose: () => void
}
export const DiplomaUnlockModal = ({
  title,
  description,
  onClose,
}: DiplomaUnlockModalProps) => {
  const { t } = useTranslation()

  useOnEscape(onClose)
  useDisableDocumentScroll()

  return (
    <Modal onClose={onClose} overflowAuto={false} ariaLabel="New certification">
      <div className={s.diplomaUnlockModal}>
        <MagicStars />
        <div
          className={s.diplomaUnlockModal__cover}
          style={backgroundImageStyle(unlockedSunrise)}
        >
          <img
            src={certificateBadgeUnlocked}
            className={s.diplomaUnlockModal__thumbnail}
            alt=""
          />
        </div>
        <div className={s.diplomaUnlockModal__content}>
          <div className={s.diplomaUnlockModal__label}>
            {t("diploma.NEW_DIPLOMA")}
          </div>
          <h3 className={s.diplomaUnlockModal__title}>{title}</h3>
          <p className={s.diplomaUnlockModal__description}>{description}</p>
          <Button onClick={onClose} borderRadius={"32"}>
            {t("diploma.NEW_DIPLOMA_UNLOCKED_CONTINUE")}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

const MagicStars = () => {
  const NUMBER_OF_STARS = 10 // Manually adjusted in CSS file
  return times(NUMBER_OF_STARS, (index) => (
    <MagicStar className={s.diplomaUnlockModal__star} key={index} />
  ))
}
