import newsColorIcon from "assets/icons/newsColorIcon.svg"
import { useGraphqlQuery } from "graphql-mock"
import { useTranslation } from "react-i18next"
import { Widget } from "../../Widget"
import { query } from "./gql"
import { NewsPost } from "./NewsPost"
import s from "./styles.module.scss"

export const NewsWidgetConnected = () => {
  const { data, error, isPending } = useGraphqlQuery(query, {})

  const newsPosts = data?.newsPosts.nodes ?? []

  return (
    <NewsWidget isLoading={isPending} error={error} newsPosts={newsPosts} />
  )
}

type NewsPostType = {
  id: string
  headline: string | null
  body: string | null
  image: string | null
}
type NewsWidgetProps = {
  isLoading: boolean
  error: unknown
  newsPosts: NewsPostType[]
}
export const NewsWidget = ({
  isLoading,
  error,
  newsPosts,
}: NewsWidgetProps) => {
  const { t } = useTranslation()
  const isEmpty = newsPosts.length === 0

  const getContent = () => {
    if (isLoading)
      return (
        <>
          <NewsPost.Skeleton />
          <NewsPost.Skeleton />
          <NewsPost.Skeleton />
        </>
      )

    if (error)
      return (
        <p className={s.widget__error}>{t("error.NEWS_WIDGET_FETCH_ERROR")}</p>
      )

    if (isEmpty) return <span>{t("news.NO_NEWS")}</span>

    return (
      <>
        {newsPosts.map((item) => (
          <NewsPost
            link={{ to: `/news/post/${item.id}` }}
            key={item.id}
            title={item.headline ?? ""}
            description={item.body ?? ""}
            image={item.image ?? ""}
          />
        ))}
      </>
    )
  }

  return (
    <Widget
      image={newsColorIcon}
      link={{ to: "/news", ariaLabel: "Open news page" }}
      title={t("news.NEWS")}
      error={error}
    >
      <ul className={s.newsPosts}>{getContent()}</ul>
    </Widget>
  )
}
