import { v3 } from "backoffice-api"
import { useGraphqlQuery } from "graphql-mock"
import { useScrollToTopOnMount } from "hooks"
import { useEffect } from "react"
import { usePageTitle } from "../../tools/usePageTitle"
import { NewsPostViewBottomBar } from "./components/NewsPostViewBottomBar"
import { NewsPostViewContent } from "./components/NewsPostViewContent"
import { NewsPostViewWrapper } from "./components/NewsPostViewWrapper"
import { NewsPostViewError } from "./error"
import { query } from "./gql"
import { NewsPostViewSkeleton } from "./loading"

export const NewsPostViewConnected = ({ id }: { id: string }) => {
  const { data, error, isPending, isSuccess } = useGraphqlQuery(query, { id })
  useScrollToTopOnMount()

  // We report user viewing the news post only when it successfully loads.
  useReportNewsView(id, isSuccess)

  if (isPending) return <NewsPostView.Skeleton />
  if (error) return <NewsPostView.Error error={error} />

  const { newsPost } = data

  return <NewsPostView newsPost={newsPost} />
}

type NewsPostViewProps = {
  newsPost: {
    body: string | null
    headline: string | null
    id: string
    image: string | null
    isLiked: boolean
    likeCount: number
    updatedAt: string
  }
}
export const NewsPostView = ({ newsPost }: NewsPostViewProps) => {
  const { body, headline, id, image, isLiked, likeCount, updatedAt } = newsPost

  usePageTitle(headline)

  return (
    <NewsPostViewWrapper>
      <NewsPostViewContent body={body} headline={headline} image={image} />
      <NewsPostViewBottomBar
        id={id}
        isLiked={isLiked}
        likeCount={likeCount}
        updatedAt={updatedAt}
      />
    </NewsPostViewWrapper>
  )
}

// When user opens the news page for the first time, it counts as an unique
// "view" reaction. This is used to track the popularity of the news post.
const useReportNewsView = (news_post_id: string, isSuccess: boolean) => {
  useEffect(() => {
    if (isSuccess)
      v3.createNewsPostReaction({
        news_post_id,
        reaction_type: "view",
      })
        // Backend will throw an error when we try to report a view from user id
        // that already had a view reaction for the same news post. We catch it
        // and ignore because it's not a real "error", just a hacky way to make
        // sure tracked reactions are unique
        .catch(console.warn)
  }, [news_post_id, isSuccess])
}

NewsPostView.Skeleton = NewsPostViewSkeleton
NewsPostView.Error = NewsPostViewError
