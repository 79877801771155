import { ProductCardsLoading } from "../../../components/ProductCard/loading"
import { ProductList } from "../../../components/ProductList"

export const ITEMS_PER_PAGE = 18

export const BundleProductsSkeleton = () => {
  return (
    <ProductList>
      <ProductCardsLoading count={ITEMS_PER_PAGE} />
    </ProductList>
  )
}
