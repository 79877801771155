import type { FetchNextPageOptions } from "@tanstack/react-query"
import { useInfiniteGraphqlQuery } from "graphql-mock"
import { last } from "lodash-es"
import { WhenVisible } from "utility-components"
import { ProductCard } from "../../../components/ProductCard"
import { ProductCardsLoading } from "../../../components/ProductCard/loading"
import { ProductList } from "../../../components/ProductList"
import { BundleProductsError } from "./error"
import { query, type Data } from "./gql"
import { BundleProductsSkeleton, ITEMS_PER_PAGE } from "./loading"

export const BundleProductsConnected = ({ id }: { id: string }) => {
  const { data, error, isPending, fetchNextPage, isFetchingNextPage } =
    useInfiniteGraphqlQuery(
      query,
      { id, limit: ITEMS_PER_PAGE },
      { getNextPage: (data) => data.productBundle.products.pageInfo.nextPage }
    )

  if (isPending) return <BundleProducts.Skeleton />
  if (error) return <BundleProducts.Error error={error} />

  const pageInfo = last(data.pages)?.productBundle.products.pageInfo
  if (!pageInfo) throw new Error("There must be at least one page")

  const products = data.pages.flatMap(
    (page) => page.productBundle.products.nodes
  )

  return (
    <BundleProducts
      products={products}
      nextPageItemCount={pageInfo.nextPageItemCount}
      fetchNextPage={fetchNextPage}
      isFetchingNextPage={isFetchingNextPage}
    />
  )
}

export const BundleProducts = ({
  products,
  nextPageItemCount,
  fetchNextPage,
  isFetchingNextPage,
}: {
  products: Data["productBundle"]["products"]["nodes"]
  nextPageItemCount: number
  fetchNextPage: (options?: FetchNextPageOptions) => void
  isFetchingNextPage: boolean
}) => {
  return (
    <>
      <ProductList>
        {products.map((data) => (
          <ProductCard key={data.id} data={data} />
        ))}
        {isFetchingNextPage && (
          <ProductCardsLoading count={nextPageItemCount} />
        )}
      </ProductList>

      <WhenVisible
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        key={products.length}
      />
    </>
  )
}

BundleProducts.Error = BundleProductsError
BundleProducts.Skeleton = BundleProductsSkeleton
