import { useWindowWidth } from "materia"
import { DiplomaWidgetMobile } from "../DiplomaWidgetMobile"
import { EventWidgetMobile } from "../EventWidgetMobile"
import { NewsWidgetMobile } from "../NewsWidgetMobile"
import { RecentActivityWidgetMobile } from "../RecentActivityWidgetMobile"
import s from "./styles.module.scss"

export const MobileWidgets = () => {
  const { isBigWindow } = useWindowWidth()

  // TODO remember to fix this in the rewrite of component functionality
  const shouldRenderDiplomaWidget = import.meta.env.MODE !== "test"

  if (isBigWindow) return

  return (
    <div className={s.mobileWidgets}>
      {shouldRenderDiplomaWidget && <DiplomaWidgetMobile />}
      <EventWidgetMobile />
      <NewsWidgetMobile />
      <RecentActivityWidgetMobile />
    </div>
  )
}
