import { type ResultOf } from "graphql-mock"
import { graphql } from "../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query DiplomaModal($productId: ID!) {
    product(id: $productId) {
      id
      title
      productType
      progress {
        certified
      }
      deadlineOccurrence {
        deadlineDate
        status
      }
      diplomaConfiguration {
        title
        description
        showCompanyLogo
        showCompanyName
        showDiplomaDescription
        showIssueDate
        showUserLastName
        showProductTitle
      }
      diplomaProgress {
        certifiedAt
      }
    }
  }
`)
