import { Navigate } from "@tanstack/react-location"
import { Card, ErrorFallback } from "materia"
import { useTranslation } from "react-i18next"
import { useCurrentCompanyContext } from "../../components/AppShell/CurrentCompanyProvider"
import { ForYouViewLoading } from "../ForYouView/loading"

/**
 * The purpose of this view is to be displayed before we know
 * which view is set as a default one for the current company.
 * Besides redirecting to a correct view it does not really do
 * anything in particular by itself.
 */
export const HomeViewConnected = () => {
  const {
    currentCompany: { portalLandingPage },
    currentCompanyError,
    isCurrentCompanyPending,
  } = useCurrentCompanyContext()

  return (
    <HomeView
      landingPage={portalLandingPage}
      isLoading={isCurrentCompanyPending}
      error={currentCompanyError}
    />
  )
}

type HomeViewProps = {
  landingPage: string
  isLoading: boolean
  error: unknown
}
export const HomeView = ({ landingPage, isLoading, error }: HomeViewProps) => {
  const { t } = useTranslation()
  const getLandingPage = () => {
    if (landingPage === "for_you") return "for-you"
    return landingPage
  }

  if (isLoading) {
    return <ForYouViewLoading />
  }

  if (error) {
    return (
      <Card>
        <ErrorFallback error={error} title={t("error.GENERIC")} />
      </Card>
    )
  }

  return <Navigate replace to={getLandingPage()} />
}
