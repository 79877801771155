import { ReactComponent as LikeIcon } from "assets/icons/likeIcon.svg"
import starBackground from "assets/icons/likeIconStarBackground.svg"
import { useGraphqlMutation } from "graphql-mock"
import { useOptimisticUpdate } from "hooks"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { mutation } from "./gql"
import s from "./styles.module.scss"

type Props = {
  newsPostId: string
  isLiked: boolean
}
export const NewsPostLikeButtonConnected = ({
  newsPostId,
  isLiked: isLikedLive,
}: Props) => {
  const { mutate } = useGraphqlMutation(mutation)

  const [isLiked, updateIsLiked, isUpdated] = useOptimisticUpdate({
    liveValue: isLikedLive,
    updateFn: (isLiked) => mutate({ input: { newsPostId, isLiked } }),
  })

  const onClick = () => {
    updateIsLiked(!isLiked)
  }

  return (
    <NewsPostLikeButton
      isDisabled={false}
      isLiked={isLiked}
      onClick={onClick}
      shouldAnimate={isUpdated}
    />
  )
}

type NewsPostLikeButtonProps = {
  isDisabled: boolean
  isLiked: boolean
  shouldAnimate: boolean
  onClick: () => void
}
export const NewsPostLikeButton = ({
  isDisabled,
  isLiked,
  shouldAnimate,
  onClick,
}: NewsPostLikeButtonProps) => {
  const { t } = useTranslation()

  return (
    <button
      className={modifiers(s, "likeButton", { isLiked, shouldAnimate })}
      onClick={onClick}
      disabled={isDisabled}
    >
      <div className={s.likeButton__iconContainer}>
        <img className={s.likeButton__background} src={starBackground} alt="" />
        <LikeIcon className={s.likeButton__icon} />
      </div>
      <span>{isLiked ? t("news.LIKED") : t("news.LIKE")}</span>
    </button>
  )
}
