import { type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>
export type RecentActivityEntry = Data["leaderboard"]["nodes"][number]

export const query = graphql(`
  query RecentActivityWidget {
    leaderboard: recentActivityEvents(limit: 1, eventType: leaderboard) {
      nodes {
        ...Event
      }
    }
    completed: recentActivityEvents(limit: 1, eventType: completed) {
      nodes {
        ...Event
      }
    }
    stars: recentActivityEvents(limit: 1, eventType: stars) {
      nodes {
        ...Event
      }
    }
  }

  fragment Event on Event {
    id
    createdAt
    eventType
    rank
    stars
    product {
      title
    }
    user {
      avatar
      avatarTitle
      displayName
    }
  }
`)
