import activityLeaderboardIcon from "assets/icons/activityLeaderboardIcon.svg"
import completedIcon from "assets/icons/completedIcon.svg"
import recentActivityIcon from "assets/icons/recentActivityIcon.svg"
import { useGraphqlQuery } from "graphql-mock"
import type { TFunction } from "i18next"
import { useTranslation } from "react-i18next"
import { exists } from "utils"
import { useGetOrdinal } from "../../../dataUtilities/getOrdinal"
import { Avatar } from "../../Avatar"
import { Stars } from "../../Stars"
import { Widget } from "../../Widget/Widget"
import { query, type RecentActivityEntry } from "./gql"

import s from "./styles.module.scss"

export const RecentActivityWidgetConnected = () => {
  const { data, error, isPending } = useGraphqlQuery(query, {})

  const { completed, leaderboard, stars } = data ?? {}

  const activityEntries = [completed, leaderboard, stars]
    .map((activityEntry) => activityEntry?.nodes[0])
    .filter(exists)

  if (activityEntries.length === 0) return

  return (
    <RecentActivityWidget
      isLoading={isPending}
      error={error}
      activityEntries={activityEntries}
    />
  )
}

type RecentActivityWidgetProps = {
  isLoading: boolean
  error: unknown
  activityEntries: RecentActivityEntry[]
}
export const RecentActivityWidget = ({
  isLoading,
  error,
  activityEntries,
}: RecentActivityWidgetProps) => {
  const { t } = useTranslation()

  const getContent = () => {
    if (isLoading)
      return (
        <>
          <ActivityEntry.Skeleton />
          <ActivityEntry.Skeleton />
          <ActivityEntry.Skeleton />
        </>
      )

    if (error) return <>{t("error.RECENT_ACTIVITY_FETCH_ERROR")}</>

    return (
      <>
        {activityEntries.map((entry, index) => (
          <ActivityEntry
            key={index}
            avatar={entry.user.avatar}
            avatarTitle={entry.user.avatarTitle}
            createdAt={entry.createdAt}
            displayName={entry.user.displayName}
            eventType={entry.eventType}
            productTitle={entry.product.title}
            rank={entry.rank}
            stars={entry.stars}
          />
        ))}
      </>
    )
  }

  return (
    <Widget
      title={t("activityFeed.activity")}
      image={recentActivityIcon}
      error={error}
    >
      <ul className={s.activityEntries}>{getContent()}</ul>
    </Widget>
  )
}

// TODO [api-decoupling]
// Interesting thing: there is this component called RecentActivityWidgetModal
// which lives in its own route. It can be opened by clicking the mobile widget
// with recent activity. Its design is pretty much the same as this widget.
// Those two could be reconcilled, but not in this PR.
type ActivityEntryProps = {
  avatar: string
  avatarTitle: string
  displayName: string | null
  productTitle: string | null
  createdAt: string
  eventType: string
  rank: number | null
  stars: number | null
}
const ActivityEntry = ({
  avatar,
  avatarTitle,
  displayName,
  productTitle,
  createdAt,
  eventType,
  rank,
  stars,
}: ActivityEntryProps) => {
  const { t } = useTranslation()
  const getOrdinal = useGetOrdinal()

  const getSummaryText = () => {
    if (eventType === "stars" && stars)
      return t("activityFeed.stars", { count: stars })
    if (eventType === "leaderboard" && rank) return getOrdinal(rank)
    return t("activityFeed.completed")
  }

  const getIcon = () => {
    if (eventType === "completed") return completedIcon
    if (eventType === "leaderboard") return activityLeaderboardIcon
  }

  return (
    <li className={s.entry}>
      <span className={s.entry__avatarWrapper}>
        <Avatar
          className={s.entry__avatarWrapper}
          alt={avatarTitle}
          image={avatar}
          size="medium"
        />
      </span>

      <div className={s.entry__name}>{displayName}</div>
      <div className={s.entry__time}>{displayTimestamp(createdAt, t)}</div>
      <div className={s.entry__summary}>
        {eventType === "stars" ? (
          <Stars count={1} max={1} size="extraSmall" />
        ) : (
          <img className={s.entry__icon} src={getIcon()} alt="" />
        )}
        <div className={s.entry__summaryText}>{getSummaryText()}</div>
        <div className={s.entry__product}>{productTitle}</div>
      </div>
    </li>
  )
}

ActivityEntry.Skeleton = () => (
  <li className={s.entry}>
    <span className={s.entry__avatarWrapper}>
      <Avatar.Skeleton size="medium" />
    </span>
    <div className={s.skeleton__title} />
    <div className={s.skeleton__summary} />
  </li>
)

const displayTimestamp = (timestamp: string, t: TFunction) => {
  const time = new Date(timestamp)
  const now = new Date()
  const difference = now.getTime() - time.getTime()

  const ONE_HOUR = 1000 * 60 * 60
  const ONE_DAY = ONE_HOUR * 24
  const THREE_DAYS = ONE_DAY * 3

  const hours = Math.floor(difference / ONE_HOUR)
  const days = Math.floor(difference / ONE_DAY)

  if (difference < ONE_HOUR) return t("date.JUST_NOW")
  if (difference < ONE_DAY) return hours + t("date.HOUR_SHORT")
  if (difference < THREE_DAYS) return days + t("date.DAY_SHORT")
  return t("date.A_WHILE_AGO")
}
