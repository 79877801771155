import { useTranslation } from "react-i18next"
import { Likes } from "../../../../components/Likes"
import {
  NewsPostLikeButton,
  NewsPostLikeButtonConnected,
} from "../../../../components/NewsPostLikeButton"
import { getElapsedTime } from "../../../../dataUtilities/getElapsedTime"
import s from "./styles.module.scss"

type Props = {
  id: string
  isLiked: boolean
  likeCount: number
  updatedAt: string
}
export const NewsPostViewBottomBar = ({
  id,
  isLiked,
  likeCount,
  updatedAt,
}: Props) => {
  const { i18n } = useTranslation()

  // TODO: in case we ever use bottom bar in another place,
  // this should be made into proper <BottomBar /> component
  return (
    <div className={s.bottom}>
      <NewsPostLikeButtonConnected newsPostId={id} isLiked={isLiked} />
      <Likes count={likeCount} />
      <div className={s.bottom__date}>
        {getElapsedTime({
          date: updatedAt,
          locale: i18n.language,
        })}
      </div>
    </div>
  )
}

const Skeleton = () => (
  <div className={s.skeleton}>
    <NewsPostLikeButton
      isDisabled
      isLiked={false}
      shouldAnimate={false}
      onClick={() => {}}
    />
    <Likes count={undefined} />
    <div className={s.skeleton__date} />
  </div>
)

const Error = () => (
  <div className={s.bottom}>
    <NewsPostLikeButton
      isDisabled
      isLiked={false}
      shouldAnimate={false}
      onClick={() => {}}
    />
    <Likes count={undefined} />
    <div className={s.bottom__date}>—</div>
  </div>
)

NewsPostViewBottomBar.Skeleton = Skeleton
NewsPostViewBottomBar.Error = Error
