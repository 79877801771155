import aboutIcon from "assets/icons/aboutIcon.svg"
import { MenuList } from "materia"
import { useTranslation } from "react-i18next"
import { BigItem } from "../components"

type DropdownAboutProps = { privacyPolicy: string | null }
export const DropdownAbout = (props: DropdownAboutProps) => {
  const { t } = useTranslation()

  // TODO [WEB-19290]
  // MenuList is a copy of Dropdown - those two should be reconcilled.
  // It should also have a Chevron indicating that it's a dropdown
  return (
    <MenuList>
      <MenuList.CustomButton>
        <BigItem
          title={t("settings.ABOUT")}
          icon={aboutIcon}
          isInteractive={false}
        />
      </MenuList.CustomButton>
      <MenuList.Items>
        <AboutList {...props} />
      </MenuList.Items>
    </MenuList>
  )
}

const AboutList = ({ privacyPolicy }: DropdownAboutProps) => {
  const { t } = useTranslation()

  return (
    <>
      <MenuList.ExternalLinkItem href="https://legal.attensi.com/min-spec.html">
        {t("settings.MINIMUM_TECHNICAL_SPECIFICATION")}
      </MenuList.ExternalLinkItem>

      {privacyPolicy && (
        <MenuList.ExternalLinkItem href={privacyPolicy}>
          {t("settings.PRIVACY_POLICY")}
        </MenuList.ExternalLinkItem>
      )}

      <MenuList.ExternalLinkItem href="https://legal.attensi.com/eula.html">
        {t("settings.TERMS_OF_USE")}
      </MenuList.ExternalLinkItem>

      <MenuList.ExternalLinkItem href="https://legal.attensi.com/licenses.html">
        {t("settings.LICENSES")}
      </MenuList.ExternalLinkItem>

      <MenuList.ExternalLinkItem href="https://legal.attensi.com/cookie-policy.html">
        {t("settings.COOKIE_POLICY")}
      </MenuList.ExternalLinkItem>
    </>
  )
}
