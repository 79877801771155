import { type ResultOf } from "graphql-mock"
import { productCardFragment } from "../../../components/ProductCard/gql"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(
  `
    query BundleProducts($id: ID!, $page: Int, $limit: Int!) {
      productBundle(id: $id) {
        id
        products(limit: $limit, page: $page) {
          pageInfo {
            nextPage
            nextPageItemCount
          }
          nodes {
            ...ProductCard
          }
        }
      }
    }
  `,
  [productCardFragment]
)
