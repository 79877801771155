import arrow from "assets/icons/arrowRight.svg"
import { ReactComponent as DiplomaUnlockedIcon } from "assets/icons/diplomaUnlockedSmall.svg"
import { useGraphqlQuery } from "graphql-mock"
import { Card } from "materia"
import { useTranslation } from "react-i18next"
import { FiestaImage, FiestaLink } from "utility-components"
import { DiplomaWidgetMobileError } from "./DiplomaWidgetMobileError"
import { DiplomaWidgetMobileSkeleton } from "./DiplomaWidgetMobileSkeleton"
import { query, type Data } from "./gql"
import s from "./styles.module.scss"

export const DiplomaWidgetMobile = () => {
  const { data, isPending, error } = useGraphqlQuery(query, {
    page: 1,
    limit: 500, // high number to fetch all diplomas without using pagination
  })

  if (isPending) return <DiplomaWidgetMobile.Skeleton />
  if (error) return <DiplomaWidgetMobile.Error error={error} />

  return <DiplomaWidgetMobile.Load diplomas={data?.products.nodes} />
}

const Load = ({ diplomas }: { diplomas: Data["products"]["nodes"] }) => {
  const { t } = useTranslation()

  if (!diplomas.length) return null

  const count = diplomas.filter((diploma) => diploma.progress.certified).length
  const maxCount = diplomas.length

  return (
    <Card borderRadius="24">
      <div className={s.widget}>
        <div className={s.diploma}>
          <div className={s.diploma__content}>
            <div className={s.diploma__icon}>
              <DiplomaUnlockedIcon />
            </div>
            <div className={s.diploma__info}>
              <div className={s.diploma__heading}>
                {t("diploma.CERTIFICATES")}
              </div>
              <div className={s.diploma__text}>
                {count} / {maxCount} {t("diploma.WIDGET_ATTAINED")}
              </div>
            </div>
          </div>
          <FiestaLink
            to="/me"
            className={s.diploma__arrow}
            ariaLabel="Open my profile"
          >
            <FiestaImage
              className={s.diploma__arrowIcon}
              src={arrow}
              alt="Arrow icon"
              sizes="60px"
            />
          </FiestaLink>
        </div>
      </div>
    </Card>
  )
}

DiplomaWidgetMobile.Skeleton = DiplomaWidgetMobileSkeleton
DiplomaWidgetMobile.Error = DiplomaWidgetMobileError
DiplomaWidgetMobile.Load = Load
