import { Modal, Button } from "materia"
import type { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import s from "./ConfirmationModal.module.scss"

export const ConfirmationModal = ({
  title,
  description,
  confirmButtonText,
  onCancel,
  onConfirm,
}: {
  title: string
  description: string
  confirmButtonText: string
  onCancel: () => void
  onConfirm: () => void
}) => {
  return (
    <Modal ariaLabel="Confirmation modal" onClose={onCancel}>
      <ModalContent>
        <ModalTitle title={title} />
        <ModalMessage message={description} />

        <ModalActionButtons>
          <ModalPrimaryButton onClick={onConfirm}>
            {confirmButtonText}
          </ModalPrimaryButton>
          <ModalCancelButton onClick={onCancel} />
        </ModalActionButtons>
      </ModalContent>
    </Modal>
  )
}

const ModalContent = ({ children }: { children: ReactNode }) => {
  return <div className={s.modalContent}>{children}</div>
}

const ModalTitle = ({ title }: { title: string }) => {
  return <div className={s.modalContent__title}>{title}</div>
}

const ModalMessage = ({ message }: { message: string }) => {
  return <div className={s.modalContent__message}>{message}</div>
}

const ModalActionButtons = ({ children }: { children: ReactNode }) => {
  return <div className={s.modalContent__actionButtons}>{children}</div>
}

const ModalPrimaryButton = ({
  onClick,
  children,
}: {
  onClick: () => void
  children: ReactNode
}) => {
  return <Button onClick={onClick}>{children}</Button>
}

const ModalCancelButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation()

  return (
    <Button theme="cancel" onClick={onClick}>
      {t("actions.CANCEL")}
    </Button>
  )
}
