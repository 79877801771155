import avatarIcon from "assets/icons/avatarIcon.svg"
import { ForceStateContext, useGraphqlQuery } from "graphql-mock"
import { createContext, useContext, type ReactNode } from "react"
import { ErrorPopup } from "utility-components"
import { query, type Data } from "./gql"

export type CurrentUser = Data["currentUser"]
type CurrentUserContextType = {
  currentUser: CurrentUser
  currentUserError: Error | null
  isCurrentUserPending: boolean
}

export const CurrentUserContext = createContext<CurrentUserContextType | null>(
  null
)

export const useCurrentUserContext = () => {
  const context = useContext(CurrentUserContext)

  if (context === null) {
    console.error(
      "CurrentUserContext is not present. You're probably trying to access it outside the boundary of the CurrentUserProvider"
    )
    throw Error("CurrentUserContext is not present")
  }

  return context
}

const initialCurrentUser: CurrentUser = {
  id: "0",
  anonymous: false,
  avatar: "",
  avatarTitle: "",
  department: "",
  displayName: "",
  email: "",
  firstName: "",
  lastName: "",
  name: "",
  phoneNumber: "",
  portalLocale: "",
  position: "",
  roles: [],
  username: "",
}

// Note: The context is only intended to provide the "current user" data.
// Add only new properties if they exist on the current user. If you wish to provide other global data, consider a dedicated Context.
export const CurrentUserProvider = ({ children }: { children: ReactNode }) => {
  const { data, error, isPending } = useGraphqlQuery(query, {})

  // Current company data is crucial -
  // the app should fail if this data cannot be retrieved.
  if (error) return <ErrorPopup error={error} />

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser: data?.currentUser || initialCurrentUser,
        currentUserError: error,
        isCurrentUserPending: isPending,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  )
}

// Mocked version of CurrentUserProvider, to be used exclusively as a Storybook decorator.
export const MockedCurrentUserProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  /**
   * TODO [api-decoupling] ForceStateContext used here is a temporary solution to handle
   * the fact that some components are coupled with API, and some are actively refactored
   * not to be. It allows us to unify the two approaches, so we can properly mock entire views.
   *
   * This should be removed once all components are decoupled from the API!
   */
  const { forceState } = useContext(ForceStateContext)
  const isCurrentUserPending = forceState === "loading"

  const mockedCurrentUser = {
    id: "0",
    anonymous: false,
    avatar: avatarIcon,
    avatarTitle: "Very fancy avatar title",
    department: "Department of dark arts",
    displayName: "Display name",
    email: "mail@mail.com",
    firstName: "Gandalf",
    lastName: "Potter",
    name: "Gandalf Potter",
    phoneNumber: "12345678",
    portalLocale: "nb-NO",
    position: "Manager",
    roles: [],
    username: "DragonKiller3000",
  }

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser: mockedCurrentUser,
        currentUserError: null,
        isCurrentUserPending,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  )
}
