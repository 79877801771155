import attensiLogoGrey from "assets/logos/attensiLogoGrey.svg"
import { useTranslation } from "react-i18next"
import { APP_VERSION } from "../../../../vuplex/constants"

import s from "./styles.module.scss"

export const HeaderMenuFooter = () => {
  const { t } = useTranslation()

  return (
    <div className={s.footer}>
      {APP_VERSION && (
        <div className={s.footer__appVersion}>
          {t("settings.APP_VERSION")} {APP_VERSION}
        </div>
      )}

      <img
        className={s.footer__logo}
        src={attensiLogoGrey}
        alt="Attensi logo"
      />
    </div>
  )
}
