import s from "./styles.module.scss"

export const BundleHeaderSkeleton = () => {
  return (
    <div className={s.headerSkeleton}>
      <div className={s.headerSkeleton__centered}>
        <div className={s.headerSkeleton__widgetLayoutSize}>
          <div className={s.headerSkeleton__back}></div>
          <div className={s.headerSkeleton__title} />
          <div className={s.headerSkeleton__stars} />
        </div>
      </div>
    </div>
  )
}
