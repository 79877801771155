import newsCardIllustration from "assets/illustrations/newsCardIllustration.svg"

import { useGraphqlQuery } from "graphql-mock"
import { useScrollToTopOnMount } from "hooks"
import { ErrorFallback } from "materia"
import { useTranslation } from "react-i18next"
import { HiddenHeader } from "../../components/HiddenHeader"
import { getElapsedTime } from "../../dataUtilities/getElapsedTime"
import { usePageTitle } from "../../tools/usePageTitle"
import { query, initialData } from "./gql"
import { NewsCard } from "./NewsCard/NewsCard"
import s from "./styles.module.scss"

export const NewsViewConnected = () => {
  const { data = initialData, error, isPending } = useGraphqlQuery(query, {})

  return (
    <NewsView
      isLoading={isPending}
      error={error}
      newsPosts={data.newsPosts.nodes}
    />
  )
}

type NewsPost = {
  body: string | null
  headline: string | null
  id: string
  image: string | null
  isLiked: boolean
  likeCount: number
  updatedAt: string
}

export const NewsView = ({
  isLoading,
  error,
  newsPosts,
}: {
  isLoading: boolean
  error: Error | null
  newsPosts: NewsPost[]
}) => {
  const { t, i18n } = useTranslation()

  useScrollToTopOnMount()
  usePageTitle(t("pageTitle.NEWS"))

  const getComponent = () => {
    if (isLoading)
      return (
        <>
          <NewsCard.Skeleton />
          <NewsCard.Skeleton />
          <NewsCard.Skeleton />
        </>
      )
    if (error) return <ErrorFallback error={error} title={t("error.GENERIC")} /> // TODO: make a proper error page component - could probably be used for all pages that has error on load

    const isEmpty = newsPosts.length === 0

    if (isEmpty) {
      return <Empty description={t("notFound.NOTFOUND_NEWS")} />
    }

    return newsPosts.map((post) => (
      <NewsCard
        key={post.id}
        link={{ to: `/news/post/${post.id}` }}
        title={post.headline ?? ""}
        displayDate={getElapsedTime({
          date: post.updatedAt,
          locale: i18n.language,
        })}
        image={post.image ?? ""}
        description={post.body ?? ""}
        numberOfLikes={post.likeCount}
        readMoreText={t("news.READ_MORE")}
        isLiked={post.isLiked}
        newsPostId={post.id}
      />
    ))
  }

  return (
    <div className={s.view}>
      <HiddenHeader title={t("navigation.NEWS")} tag="h1" />
      <ul className={s.view__posts}>{getComponent()}</ul>
    </div>
  )
}

type EmptyProps = {
  description: string
}
const Empty = ({ description }: EmptyProps) => (
  <div className={s.empty}>
    <img src={newsCardIllustration} className={s.empty__illustration} alt="" />
    <div className={s.empty__text}>{description}</div>
  </div>
)
