import { auth } from "auth"
import { useTranslation } from "react-i18next"
import { SmallItem } from "../components"

export const ButtonDeleteMe = () => {
  const { t } = useTranslation()

  const onClick = async () => {
    const link = await auth.addAccessParams("https://delete-me.attensi.com")
    window.location.href = link
  }

  return <SmallItem onClick={onClick}>{t("settings.DELETE_MY_DATA")}</SmallItem>
}
