import { type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query EventWidget {
    products(limit: 3, input: { variant: events }) {
      nodes {
        id
        title
        course {
          isAttending
          startDatetime
        }
      }
    }
  }
`)
