import closeIcon from "assets/icons/closeIcon.svg"
import { Modal } from "materia"
import type { PropsWithChildren, ReactNode } from "react"
import { KpiSalesWidget } from "../KpiSalesWidget"
import s from "./KpiSalesModal.module.scss"

type KpiSalesModalProps = {
  onClose: () => void
  children: ReactNode
}
export const KpiSalesModal = ({ onClose, children }: KpiSalesModalProps) => (
  <Modal onClose={onClose} ariaLabel="Kpi sales">
    <div className={s.kpiSalesModal}>{children}</div>
  </Modal>
)

type CloseButtonProps = {
  onClose: () => void
}
const CloseButton = ({ onClose }: CloseButtonProps) => (
  <button className={s.closeButton} onClick={onClose} aria-label="Close">
    <img className={s.closeButton__icon} src={closeIcon} alt="" />
  </button>
)

const Top = ({ children }: PropsWithChildren) => (
  <div className={s.top}>{children}</div>
)

const Skeleton = () => <div className={s.skeleton} />

KpiSalesModal.CloseButton = CloseButton
KpiSalesModal.Top = Top
KpiSalesModal.Skeleton = Skeleton
KpiSalesModal.Header = KpiSalesWidget.Header
KpiSalesModal.Title = KpiSalesWidget.Title
KpiSalesModal.Chart = KpiSalesWidget.Chart
KpiSalesModal.PaginationArrow = KpiSalesWidget.PaginationArrow
KpiSalesModal.Entries = KpiSalesWidget.Entries
KpiSalesModal.Entry = KpiSalesWidget.Entry
KpiSalesModal.Summary = KpiSalesWidget.Summary
KpiSalesModal.SummaryItem = KpiSalesWidget.SummaryItem
