import { Card } from "materia"
import { modifiers, sanitizeHtml } from "ui-tools"
import {
  FiestaImage,
  FiestaLink,
  type FiestaLinkProps,
} from "utility-components"
import { Likes } from "../../../components/Likes"
import { NewsPostLikeButtonConnected } from "../../../components/NewsPostLikeButton"
import s from "./NewsCard.module.scss"

type NewsCardProps = {
  link: FiestaLinkProps
  title: string
  displayDate: string
  image: string
  description: string
  numberOfLikes: number
  readMoreText: string
  isLiked: boolean
  newsPostId: string
}
export const NewsCard = ({
  link,
  title,
  displayDate,
  image,
  description,
  numberOfLikes,
  readMoreText,
  isLiked,
  newsPostId,
}: NewsCardProps) => (
  <li>
    <Card borderRadius="24">
      <div className={modifiers(s, "newsCard", { isLiked })}>
        <FiestaLink {...link} className={s.newsCard__link} ariaLabel={title}>
          <div className={s.newsCard__top}>
            <h2 className={s.newsCard__title}>{title}</h2>
            <div className={s.newsCard__date}>{displayDate}</div>
          </div>
          <FiestaImage
            className={s.newsCard__image}
            src={image}
            alt={title}
            sizes="(width = big) 500px, 100vw"
          />
        </FiestaLink>
        <div
          className={s.newsCard__description}
          dangerouslySetInnerHTML={sanitizeHtml(description, "strict")}
        />
        <div className={s.newsCard__bottom}>
          <NewsPostLikeButtonConnected
            isLiked={isLiked}
            newsPostId={newsPostId}
          />
          <Likes count={numberOfLikes} />
          <FiestaLink
            {...link}
            className={s.newsCard__readMore}
            ariaLabel={`${readMoreText}: ${title}`}
          >
            {readMoreText}
          </FiestaLink>
        </div>
      </div>
    </Card>
  </li>
)

const Skeleton = () => (
  <Card borderRadius="24">
    <div className={s.skeleton}>
      <div className={s.skeleton__top} />
      <div className={s.skeleton__image} />
      <ul className={s.skeleton__description}>
        <li className={s.skeleton__descriptionItem} />
        <li className={s.skeleton__descriptionItem} />
        <li className={s.skeleton__descriptionItem} />
      </ul>
      <div className={s.skeleton__bottom}>
        <div className={s.skeleton__likeContainer}>
          <div className={s.skeleton__likeButton} />
          <div className={s.skeleton__likes} />
        </div>
        <div className={s.skeleton__readMore} />
      </div>
    </div>
  </Card>
)

NewsCard.Skeleton = Skeleton
