import pushIcon from "assets/icons/pushNotIcon.svg"
import { useTranslation } from "react-i18next"
import { sendVuplexMessage } from "../../../../vuplex/sendVuplexMessage"
import { useVuplexStore } from "../../../../vuplex/vuplexStore"
import { ToggleItem } from "../components"

/**
 * This component relies on the pushNotificationsEnabled state from the vuplex store
 * to be visible, but it also uses it for determining if it's checked or not.
 * It does not make much sense as the menu item would just disappear once it's unchecked,
 * so I assume there is some black magic happening here.
 * TODO - investigate the black magic and MAYBE move the conditional to parent
 */
export const TogglePushNotifications = () => {
  const { t } = useTranslation()
  const { pushNotificationsEnabled } = useVuplexStore()

  if (pushNotificationsEnabled === undefined) return

  const togglePushNotifications = (pushNotificationsEnable: boolean) => {
    sendVuplexMessage({
      type: "REQUEST_PUSH_NOTIFICATIONS_ENABLED",
      payload: { pushNotificationsEnable },
    })
  }

  return (
    <ToggleItem
      title={t("settings.PUSH_NOTIFICATIONS")}
      icon={pushIcon}
      checked={pushNotificationsEnabled}
      onChange={togglePushNotifications}
    />
  )
}
