import { useNavigate } from "@tanstack/react-location"
import closeIcon from "assets/icons/closeIcon.svg"
import { Dialog } from "materia"
import { type PropsWithChildren } from "react"
import { FiestaLink } from "utility-components"
import s from "./styles.module.scss"

// Wraps the news post in components shared between all states (loading,
// error and success) so we can reuse the same dialog and close button.
export const NewsPostViewWrapper = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate()
  const closeRoute = "/news"

  const onClose = () => {
    navigate({ to: closeRoute })
  }

  // TODO: would be nice to unify Modal/Dialog components
  // and not have a separate reimplementation for fullscreen here
  return (
    <Dialog ariaLabel="News post" onClose={onClose}>
      <div className={s.view}>
        {children}
        <CloseButton closeRoute={closeRoute} />
      </div>
    </Dialog>
  )
}

type CloseButtonProps = { closeRoute: string }
const CloseButton = ({ closeRoute }: CloseButtonProps) => (
  <FiestaLink className={s.closeButton} to={closeRoute} aria-label="Close">
    <img className={s.closeButton__icon} src={closeIcon} alt="" />
  </FiestaLink>
)
