import { type ResultOf } from "graphql-mock"
import { graphql } from "../../graphql"

export type Data = ResultOf<typeof query>
export type NewsPost = Data["newsPost"]

export const query = graphql(`
  query NewsPostView($id: ID!) {
    newsPost(id: $id) {
      id
      headline
      body
      image
      likeCount
      isLiked
      updatedAt
    }
  }
`)
