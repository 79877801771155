import { useNavigate } from "@tanstack/react-location"
import { v1 } from "backoffice-api"
import { invalidateAllQueries } from "bonzai"
import { isFetchError } from "fetcher"
import { t } from "i18next"
import { Button, Checkbox, ErrorFallback, Input } from "materia"
import { forwardRef, useId, type InputHTMLAttributes } from "react"
import {
  useForm,
  type UseFormRegister,
  type UseFormSetError,
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import { UserProfileView } from ".."
import { useCurrentCompanyContext } from "../../../components/AppShell/CurrentCompanyProvider"
import { useCurrentUserContext } from "../../../components/AppShell/CurrentUserProvider"
import { sendVuplexMessage } from "../../../vuplex/sendVuplexMessage"
import type { FormDataType } from "./types"

import s from "./styles.module.scss"

export const UserProfileSettingsConnected = () => {
  const {
    currentCompanyError,
    isCurrentCompanyPending,
    currentCompany: {
      companyFeatureFlags,
      portalFeatureFlags,
      userDisplayNameFormat,
    },
  } = useCurrentCompanyContext()
  const {
    currentUserError,
    isCurrentUserPending,
    currentUser: { anonymous, email, phoneNumber, username },
  } = useCurrentUserContext()

  const isPending = isCurrentUserPending || isCurrentCompanyPending
  const errors = [currentUserError, currentCompanyError].filter(Boolean)

  const isAnonymousEnabled = companyFeatureFlags.includes("anonymous")
  const isUsernameEnabled = userDisplayNameFormat === "username"
  const isUsernameEditEnabled = !portalFeatureFlags.includes(
    "disable_username_editing"
  )
  const isPhoneNumberEditEnabled = portalFeatureFlags.includes(
    "enable_phone_number_editing"
  )

  return (
    <UserProfileSettings
      isLoading={isPending}
      errors={errors}
      settings={{
        email: email ?? "",
        phoneNumber: phoneNumber ?? "",
        userName: username ?? "",
        showUserOnLeaderboard: !anonymous,
        isUsernameEnabled,
        isUsernameEditEnabled,
        isPhoneNumberEditEnabled,
        isAnonymousEnabled,
      }}
    />
  )
}

type UserProfileSettingsProps = {
  isLoading: boolean
  errors: unknown[]
  settings: {
    email: string
    phoneNumber: string
    userName: string
    isUsernameEnabled: boolean
    isUsernameEditEnabled: boolean
    isPhoneNumberEditEnabled: boolean
    isAnonymousEnabled: boolean
    showUserOnLeaderboard: boolean
  }
}
export const UserProfileSettings = ({
  isLoading,
  errors,
  settings: {
    email,
    phoneNumber,
    userName,
    isUsernameEnabled,
    isUsernameEditEnabled,
    isPhoneNumberEditEnabled,
    isAnonymousEnabled,
    showUserOnLeaderboard,
  },
}: UserProfileSettingsProps) => {
  const { t } = useTranslation()

  const defaultValues: FormDataType = {
    avatarId: null,
    userName,
    phoneNumber,
    showUserOnLeaderboard,
  }
  const form = useForm({ defaultValues })
  const { register, formState, handleSubmit, setError } = form
  const { errors: formErrors, isSubmitting, isDirty } = formState

  const onSubmit = useOnSubmit({ setError, isPhoneNumberEditEnabled })

  if (isLoading) return <UserProfileSettingsSkeleton />
  if (errors.length > 0) return <UserProfileSettingsError errors={errors} />

  return (
    <UserProfileView.Section title={t("userProfile.SECTION_PROFILE")}>
      <form className={s.userSettingsProfile} onSubmit={handleSubmit(onSubmit)}>
        {isAnonymousEnabled && (
          <Checkbox
            label={t("userProfile.NOT_ANONYMOUS")}
            {...register("showUserOnLeaderboard")}
          />
        )}

        {isUsernameEnabled && (
          <Input
            label={t("userProfile.USERNAME")}
            errorMessage={formErrors.userName?.message}
            disabled={!isUsernameEditEnabled}
            {...register("userName", { validate: validateUserName })}
          />
        )}

        <Input label={t("userProfile.MAIL")} disabled value={email ?? ""} />

        <Input
          label={t("userProfile.MOBILE")}
          disabled={!isPhoneNumberEditEnabled}
          errorMessage={formErrors.phoneNumber?.message}
          {...register("phoneNumber", {
            validate: (phoneNumber) =>
              validatePhoneNumber({ phoneNumber, isPhoneNumberEditEnabled }),
          })}
        />

        <AvatarList register={register} />

        <Button type="submit" disabled={isSubmitting || !isDirty}>
          {t("userProfile.SAVE")}
        </Button>

        <p className={s.userSettingsProfile__error}>
          {formErrors.root?.message}
        </p>
      </form>
    </UserProfileView.Section>
  )
}

const UserProfileSettingsSkeleton = () => {
  const { t } = useTranslation()

  return (
    <UserProfileView.Section title={t("userProfile.SECTION_PROFILE")}>
      <div className={s.skeleton__setting} />
      <div className={s.skeleton__setting} />
      <div className={s.skeleton__setting} />
    </UserProfileView.Section>
  )
}

const UserProfileSettingsError = ({ errors }: { errors: unknown[] }) => {
  const { t } = useTranslation()

  return (
    <UserProfileView.Section title={t("userProfile.SECTION_PROFILE")}>
      <ErrorFallback
        error={errors}
        title={t("error.GENERIC")}
        message={t("error.COULD_NOT_LOAD")}
      />
    </UserProfileView.Section>
  )
}

// TODO Move this to a separate file :(
type AvatarListProps = { register: UseFormRegister<FormDataType> }
const AvatarList = ({ register }: AvatarListProps) => {
  const { t } = useTranslation()
  const { data: avatars = [] } = useAvatars()

  const avatarElements = avatars.map((avatar) => (
    <Avatar
      key={avatar.id}
      src={avatar.url}
      value={avatar.id}
      alt={avatar.title}
      {...register("avatarId")}
    />
  ))

  return (
    <div className={s.avatars}>
      <label className={s.avatars__label}>
        {t("userProfile.CHOOSE_AVATAR")}
      </label>
      <ul className={s.avatars__avatars} tabIndex={0}>
        {avatarElements}
      </ul>
    </div>
  )
}

type AvatarProps = InputHTMLAttributes<HTMLInputElement> & {
  src: string
  alt: string
}
const Avatar = forwardRef<HTMLInputElement, AvatarProps>(
  ({ src, alt, ...props }, ref) => {
    const id = useId()

    return (
      <li className={s.avatar}>
        <input
          type="radio"
          id={id}
          className={s.avatar__input}
          ref={ref}
          {...props}
        />
        <label className={s.avatar__label} htmlFor={id}>
          <img className={s.avatar__image} src={src} alt={alt} loading="lazy" />
        </label>
      </li>
    )
  }
)

// TODO [WEB-16831] move to GraphQL
const useAvatars = () => {
  return v1.getAvatars.useQuery([], {
    select: (res) => res.flatMap((group) => group.avatars),
  })
}

// TODO [WEB-16831] move to GraphQL
const useOnSubmit = ({
  setError,
  isPhoneNumberEditEnabled,
}: {
  setError: UseFormSetError<FormDataType>
  isPhoneNumberEditEnabled: boolean
}) => {
  const navigate = useNavigate()

  const updateUser = async (data: FormDataType) => {
    await v1.updateUser({
      anonymous: !data.showUserOnLeaderboard,
      username: data.userName,
      phone_number: isPhoneNumberEditEnabled ? data.phoneNumber : undefined,
      avatar_id: data.avatarId !== null ? data.avatarId : undefined,
    })

    sendVuplexMessage({ type: "USER_SETTINGS_UPDATE" })

    invalidateAllQueries()

    navigate({ to: "/for-you" })
  }

  return async (data: FormDataType) => {
    try {
      await updateUser(data)
    } catch (error) {
      if (isFetchError(error) && (error.json as any).username) {
        setError("userName", { message: (error.json as any).username[0] })
      } else if (isFetchError(error) && (error.json as any).phone_number) {
        setError("phoneNumber", {
          message: (error.json as any).phone_number[0],
        })
      } else {
        setError("root", { message: t("error.COULD_NOT_SAVE") })
      }
    }
  }
}

// TODO [WEB-16831] move to GraphQL
const validateUserName = async (userName: string | null) => {
  if (userName === null) return

  const me = await v1.getMe.fetchQueryOnce([])

  if (me.username === userName) return

  if (userName === "") return t("onboarding.USERNAME_EXISTS")

  const { available } = await v1.getIsUsernameAvailable(userName)
  if (!available) return t("onboarding.USERNAME_EXISTS")
}

const validatePhoneNumber = ({
  phoneNumber,
  isPhoneNumberEditEnabled,
}: {
  phoneNumber: string
  isPhoneNumberEditEnabled: boolean
}) => {
  if (!isPhoneNumberEditEnabled) return

  const isValid = phoneNumber.startsWith("+") || phoneNumber.startsWith("00")
  if (!isValid) return t("userProfile.INVALID_PHONE_NUMBER")
}
