import { useTranslation } from "react-i18next"
import { BigItem } from "../components"

type LinkYourProfileProps = {
  avatar: string
  avatarTitle: string
  position: string | null
  onClick: () => void
}
export const LinkYourProfile = ({
  avatar,
  avatarTitle,
  position,
  onClick,
}: LinkYourProfileProps) => {
  const { t } = useTranslation()

  return (
    <BigItem
      title={t("settings.YOUR_PROFILE")}
      description={position ?? ""}
      icon={avatar}
      imageDescription={avatarTitle}
      isBigIcon={true}
      onClick={onClick}
      to="/me"
    />
  )
}
