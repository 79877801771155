import { sanitizeHtml } from "ui-tools"
import {
  FiestaImage,
  FiestaLink,
  type FiestaLinkProps,
} from "utility-components"
import s from "./styles.module.scss"

type NewsPostProps = {
  description: string
  image: string
  link: FiestaLinkProps
  title: string
}
export const NewsPost = ({
  description,
  image,
  link,
  title,
}: NewsPostProps) => (
  <li>
    <FiestaLink className={s.newsPost} {...link} ariaLabel={title}>
      <h3 className={s.newsPost__title}>{title}</h3>
      <FiestaImage
        className={s.newsPost__image}
        src={image}
        alt=""
        sizes="60px"
      />
      <div
        className={s.newsPost__description}
        dangerouslySetInnerHTML={sanitizeHtml(description, "strict")}
      />
    </FiestaLink>
  </li>
)

NewsPost.Skeleton = () => {
  return (
    <li className={s.newsPost}>
      <div className={s.skeleton__title} />
      <div className={s.skeleton__image} />
      <div className={s.skeleton__description} />
      <div className={s.skeleton__description} />
    </li>
  )
}
