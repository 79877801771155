import { useScrollToTopOnMount } from "hooks"
import { Button } from "materia"
import type { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import { useNavigateBack } from "../../tools/useNavigateBack"
import { BundleProductsConnected } from "./BundleProducts"
import s from "./styles.module.scss"

export const ProductBundleView = ({ id }: { id: string }) => {
  useScrollToTopOnMount()

  return (
    <ProductBundleView.Wrapper>
      <BundleProductsConnected id={id} />
    </ProductBundleView.Wrapper>
  )
}

const Wrapper = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation()
  const navigateBack = useNavigateBack()

  return (
    <div className={s.productBundleView}>
      {children}
      <div className={s.productBundleView__back}>
        <Button onClick={() => navigateBack()}>{t("actions.BACK")}</Button>
      </div>
    </div>
  )
}

ProductBundleView.Wrapper = Wrapper
