import { Outlet, ReactLocation, Router } from "@tanstack/react-location"
import { useAuth } from "auth"
import { BonzaiProvider } from "bonzai"
import { EnvironmentContext } from "graphql-mock"
import { DialogContainer, TooltipContainer } from "materia"
import { APP_CONFIG } from "optimizer-core"
import { useState } from "react"
import { OptimizeContextProvider } from "ui-tools"
import { ErrorPopup, QueryBoundary } from "utility-components"
import { parseSearch } from "utils"
import { AIChatContextProvider } from "./components/AIChat/providers/AIChatProvider"
import { AppShellConnected } from "./components/AppShell"
import { CurrentCompanyProvider } from "./components/AppShell/CurrentCompanyProvider"
import { CurrentUserProvider } from "./components/AppShell/CurrentUserProvider"
import { ThemeProvider } from "./components/AppShell/ThemeProvider"
import { ConfettiBanner } from "./components/ConfettiBanner"
import { DiplomaUnlockModalConnected } from "./components/DiplomaUnlockModal"
import { SharedDeviceInactivityTracker } from "./components/SharedDeviceInactivityTrackerModal"
import { GRAPHQL_ENVIRONMENT } from "./constants"
import { OPTIMIZER_URL } from "./env"
import { getRoutes } from "./routes"

const APP = APP_CONFIG.find((app) => app.name === "portal")

export const App = () => (
  <BonzaiProvider>
    <EnvironmentContext.Provider value={GRAPHQL_ENVIRONMENT}>
      <OptimizeContextProvider value={{ app: APP, optimizeUrl: OPTIMIZER_URL }}>
        <TooltipContainer>
          <QueryBoundary>
            <CurrentUserProvider>
              <CurrentCompanyProvider>
                <ThemeProvider>
                  <DialogContainer>
                    <AIChatContextProvider>
                      <AppRouter />
                    </AIChatContextProvider>
                  </DialogContainer>
                </ThemeProvider>
              </CurrentCompanyProvider>
            </CurrentUserProvider>
          </QueryBoundary>
        </TooltipContainer>
      </OptimizeContextProvider>
    </EnvironmentContext.Provider>
  </BonzaiProvider>
)

const AppRouter = () => {
  const { activeSession, isPending, error } = useAuth()

  const [location] = useState(() => new ReactLocation({ parseSearch }))

  if (isPending) return
  if (error) return <ErrorPopup error={error} />

  return (
    <Router
      routes={getRoutes()}
      location={location}
      basepath={activeSession.subdomain}
    >
      <AppShellConnected>
        <Outlet />
        <ConfettiBanner />
        <DiplomaUnlockModalConnected />
        <SharedDeviceInactivityTracker />
      </AppShellConnected>
    </Router>
  )
}
