import { NewsPostViewBottomBar } from "./components/NewsPostViewBottomBar"
import { NewsPostViewContent } from "./components/NewsPostViewContent"
import { NewsPostViewWrapper } from "./components/NewsPostViewWrapper"

export const NewsPostViewError = ({ error }: { error: unknown }) => {
  return (
    <NewsPostViewWrapper>
      <NewsPostViewContent.Error error={error} />
      <NewsPostViewBottomBar.Error />
    </NewsPostViewWrapper>
  )
}
