import { Link } from "@tanstack/react-location"
import { Toggle } from "materia"
import type { ReactNode } from "react"
import { modifiers } from "ui-tools"
import s from "./styles.module.scss"

// TODO [WEB-19290]
// Refactor every single one of those
export const LinkOrButton = ({
  to,
  href,
  openInNewTab,
  isInteractive = true,
  ...props
}: {
  children: ReactNode
  className?: string
  to?: string
  href?: string
  openInNewTab?: boolean
  isInteractive?: boolean
  onClick?: () => void
}) => {
  if (!isInteractive) {
    return <div {...props} />
  }

  if (to) {
    return <Link to={to} {...props} />
  }

  if (href) {
    const target = openInNewTab ? "_blank" : undefined
    return (
      <a href={href} target={target} {...props}>
        {props.children}
      </a>
    )
  }

  return <button {...props} />
}

export const BigItem = ({
  title,
  icon,
  imageDescription = "",
  description,
  onClick,
  isBigIcon,
  ...props
}: {
  title: string
  icon: string
  imageDescription?: string
  description?: string
  href?: string
  to?: string
  isBigIcon?: boolean
  isInteractive?: boolean
  onClick?: () => void
}) => {
  return (
    <div className={modifiers(s, "bigItem", { isBigIcon })}>
      <LinkOrButton
        className={s.bigItem__linkOrButton}
        onClick={onClick}
        openInNewTab={true}
        {...props}
      >
        <img className={s.bigItem__icon} src={icon} alt={imageDescription} />
        <div className={s.bigItem__left}>
          <div className={s.bigItem__title}>{title}</div>
          {description && (
            <p className={s.bigItem__description}>{description}</p>
          )}
        </div>
      </LinkOrButton>
    </div>
  )
}

export const SmallItem = ({
  title,
  children,
  ...props
}: {
  title?: ReactNode
  children?: ReactNode
  to?: string
  href?: string
  onClick?: () => void
}) => (
  <LinkOrButton className={s.smallItem} {...props}>
    {title}
    {children}
  </LinkOrButton>
)

export const ToggleItem = ({
  checked,
  disabled,
  icon,
  title,
  onChange,
}: {
  checked: boolean
  disabled?: boolean
  icon: string
  title: string
  onChange: (checked: boolean) => void
}) => (
  <div className={s.bigItem}>
    <label className={s.bigItem__linkOrButton}>
      <img className={s.bigItem__icon} src={icon} alt="" />
      <Toggle
        label={title}
        checked={checked}
        disabled={disabled}
        onChange={(event) => onChange(event.currentTarget.checked)}
      />
    </label>
  </div>
)
