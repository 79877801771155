import { ReactComponent as LikeIcon } from "assets/icons/likeIcon.svg"
import { modifiers } from "ui-tools"
import s from "./styles.module.scss"

type LikesProps = { count: number | undefined }
export const Likes = ({ count }: LikesProps) => {
  const isDisabled = count === undefined

  return (
    <div className={modifiers(s, "likes", { isDisabled })}>
      <LikeIcon aria-label="Likes" role="img" />
      <span>{isDisabled ? "—" : count}</span>
    </div>
  )
}
