import { useMenu } from "hooks"
import { modifiers } from "ui-tools"
import { getHasAdminAccess } from "../../../dataUtilities/getHasAdminAccess"
import type { Role } from "../../../graphql"
import { IS_APP } from "../../../vuplex/constants"
import { MenuAnimation } from "../../MenuAnimation"
import {
  canUseSharedDeviceFlag,
  mustUseSharedDeviceFlag,
} from "../../SharedDeviceInactivityTrackerModal/store"
import { useCurrentCompanyContext } from "../CurrentCompanyProvider"
import { useCurrentUserContext } from "../CurrentUserProvider"
import { HeaderMenuFooter, HeaderMenuCloseButton } from "./components"
import {
  ButtonLogout,
  DropdownAbout,
  DropdownCompany,
  DropdownLanguage,
  LinkAdmin,
  LinkHelpCenter,
  LinkYourProfile,
  TogglePushNotifications,
  ToggleSharedDevice,
  ButtonDeleteMe,
} from "./HeaderMenuItems"
import s from "./styles.module.scss"

// Should be refactored be opened from the outside
export const HeaderMenuConnected = () => {
  const { currentUser, isCurrentUserPending } = useCurrentUserContext()
  const { currentCompany, isCurrentCompanyPending } = useCurrentCompanyContext()

  return (
    // TODO: [api-decoupling] HeaderMenu should probably be split up such that a menu button can open it from the outside and the the menu list itself is composed of it's items instead
    <HeaderMenu
      currentCompany={currentCompany}
      currentUser={currentUser}
      isLoading={isCurrentCompanyPending || isCurrentUserPending}
    />
  )
}

type HeaderMenuProps = {
  isLoading?: boolean
  currentUser: {
    avatar: string
    avatarTitle: string
    position: string | null
    roles: Role[]
  }
  currentCompany: {
    companyFeatureFlags: string[]
    logoUrl: string
    name: string | null
    portalFeatureFlags: string[]
    portalSupportedLocales: string[]
    selfRegistration: boolean
    privacyPolicy: string | null
  }
  isInitialOpen?: boolean // TODO: remove from the Header menu itself - The menu should not know about opening itself
}
export const HeaderMenu = ({
  currentCompany,
  currentUser,
  isLoading,
  isInitialOpen,
}: HeaderMenuProps) => {
  const { isOpen, toggleMenu, containerRef } = useMenu(isInitialOpen)
  const { avatar, avatarTitle, position, roles } = currentUser
  const {
    selfRegistration,
    name,
    portalSupportedLocales,
    privacyPolicy,
    logoUrl,
    companyFeatureFlags,
    portalFeatureFlags,
  } = currentCompany

  // isSharedDeviceEnabled is true if either of the flags are present in the BE,
  // making it possible to set the device as shared.
  const isSharedDeviceEnabled = companyFeatureFlags.some((flag) =>
    [canUseSharedDeviceFlag, mustUseSharedDeviceFlag].includes(flag)
  )
  const showHelpCenterLink = !companyFeatureFlags.includes("disable_help_links")
  const showCompanySelectionDropdown = !IS_APP
  const showUserProfileButton = !portalFeatureFlags.includes(
    "disable_user_profile"
  )
  const isSingleLogOutEnabled = companyFeatureFlags.includes(
    "enable_single_log_out"
  )
  const showAdminLink = getHasAdminAccess(roles)

  if (isLoading) return <div className={s.skeleton__headerMenu} />

  return (
    <div className={modifiers(s, "headerMenu", { isOpen })} ref={containerRef}>
      <button
        className={s.headerMenu__button}
        onClick={toggleMenu}
        title="Header menu"
        aria-expanded={isOpen}
        aria-haspopup="menu"
      />
      <MenuAnimation className={s.headerMenu__content} isOpen={isOpen}>
        <div className={s.headerMenu__items}>
          <HeaderMenuCloseButton onClick={toggleMenu} />

          {showCompanySelectionDropdown && (
            <DropdownCompany name={name} logoUrl={logoUrl} />
          )}

          {showUserProfileButton && (
            <LinkYourProfile
              avatar={avatar}
              avatarTitle={avatarTitle}
              position={position}
              onClick={toggleMenu}
            />
          )}

          <DropdownLanguage portalSupportedLocales={portalSupportedLocales} />

          {showHelpCenterLink && <LinkHelpCenter onClick={toggleMenu} />}

          <DropdownAbout privacyPolicy={privacyPolicy} />

          <TogglePushNotifications />

          {isSharedDeviceEnabled && <ToggleSharedDevice />}

          {showAdminLink && <LinkAdmin />}

          <ButtonLogout isSingleLogOutEnabled={isSingleLogOutEnabled} />

          {/* A self registered user is basically an impersonal/"guest" user, that should be allowed to delete themselves */}
          {selfRegistration && <ButtonDeleteMe />}

          <HeaderMenuFooter />
        </div>
      </MenuAnimation>
    </div>
  )
}
