import closeIcon from "assets/icons/closeIcon.svg"
import { useWindowWidth } from "materia"
import { useTranslation } from "react-i18next"

import s from "./styles.module.scss"

// TODO [WEB-19290]
// This component apparently overlaps the original HeaderMenu button
// when HeaderMenu is open.
// Just please help me God this should not exist
type HeaderMenuHeaderProps = { onClick: () => void }
export const HeaderMenuCloseButton = ({ onClick }: HeaderMenuHeaderProps) => {
  const { t } = useTranslation()
  const { isSmallWindow } = useWindowWidth()
  if (!isSmallWindow) return null

  return (
    <div className={s.header}>
      <div className={s.header__content}>
        <div className={s.header__title}>{t("MENU")}</div>
        <button
          className={s.header__button}
          onClick={onClick}
          title="Close menu"
        >
          <img src={closeIcon} className={s.header__closeIcon} alt="" />
        </button>
      </div>
    </div>
  )
}
