import { type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query DiplomaWidgetMobile($limit: Int!, $page: Int) {
    products(input: { variant: with_certificate }, limit: $limit, page: $page) {
      nodes {
        progress {
          certified
        }
      }
    }
  }
`)
