import logoutIcon from "assets/icons/logoutIcon.svg"
import { auth } from "auth"
import { useTranslation } from "react-i18next"
import { sendVuplexMessage } from "../../../../vuplex/sendVuplexMessage"
import { BigItem } from "../components"

type ButtonLogoutProps = { isSingleLogOutEnabled: boolean }
export const ButtonLogout = ({ isSingleLogOutEnabled }: ButtonLogoutProps) => {
  const { t } = useTranslation()

  const onLogout = () => {
    sendVuplexMessage({ type: "LOGOUT" })
    if (isSingleLogOutEnabled) auth.logoutAndSLORedirect()
    else auth.logoutAndLogin()
  }

  return (
    <BigItem
      title={t("settings.LOGOUT")}
      onClick={onLogout}
      icon={logoutIcon}
    />
  )
}
