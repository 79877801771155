import { Card } from "materia"
import s from "./styles.module.scss"

export const DiplomaWidgetMobileSkeleton = () => {
  return (
    <Card borderRadius="24">
      <div className={s.widget}>
        <div className={s.skeleton}>
          <div className={s.skeleton__content}>
            <div className={s.skeleton__icon} />
            <div className={s.skeleton__info}>
              <div className={s.skeleton__heading} />
              <div className={s.skeleton__name} />
            </div>
          </div>
          <div className={s.skeleton__arrow} />
        </div>
      </div>
    </Card>
  )
}
