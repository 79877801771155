import { useWindowWidth } from "materia"
import { DiplomaWidgetMobileSkeleton } from "../DiplomaWidgetMobile/DiplomaWidgetMobileSkeleton"
import { EventWidgetMobileLoading } from "../EventWidgetMobile/loading"
import { NewsWidgetMobileLoading } from "../NewsWidgetMobile/loading"
import { RecentActivityWidgetMobileLoading } from "../RecentActivityWidgetMobile"
import s from "./styles.module.scss"

export const MobileWidgetsLoading = () => {
  const { isBigWindow } = useWindowWidth()
  if (isBigWindow) return

  return (
    <div className={s.skeleton}>
      <DiplomaWidgetMobileSkeleton />
      <EventWidgetMobileLoading />
      <NewsWidgetMobileLoading />
      <RecentActivityWidgetMobileLoading />
    </div>
  )
}
